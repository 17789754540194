import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled, { css } from 'styled-components';

import Container from './Container';
import Title from './Title';
import CustomerReview from './CustomerReview';

const Wrapper = styled.section`
  ${({ theme }) => css`
    text-align: center;
    background-color: ${theme.colors.neutral.gray1500};
  `}
`;

const Reviews = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: ${({ theme }) => theme.spacers.normal};
  margin-top: 3rem;
`;

const CustomerReviews = () => {
  const {
    chaiwat,
    nantawat,
    teerapat,
    thanapong,
    samai,
    sudjai,
    watchara,
  } = useStaticQuery(graphql`
    query {
      chaiwat: imageSharp(original: { src: { regex: "/chaiwat/" } }) {
        fixed(width: 120, height: 120) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
      nantawat: imageSharp(original: { src: { regex: "/nantawat/" } }) {
        fixed(width: 120, height: 120) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
      teerapat: imageSharp(original: { src: { regex: "/teerapat/" } }) {
        fixed(width: 120, height: 120) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
      thanapong: imageSharp(original: { src: { regex: "/thanapong/" } }) {
        fixed(width: 120, height: 120) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
      samai: imageSharp(original: { src: { regex: "/samai/" } }) {
        fixed(width: 120, height: 120) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
      sudjai: imageSharp(original: { src: { regex: "/sudjai/" } }) {
        fixed(width: 120, height: 120) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
      watchara: imageSharp(original: { src: { regex: "/watchara/" } }) {
        fixed(width: 120, height: 120) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  `);

  return (
    <Wrapper>
      <Container>
        <Title color="white">ความประทับใจจากลูกค้าเรา</Title>
        <Reviews>
          <CustomerReview
            avatar={samai}
            name="สมัย เสริฐเจิม"
            attend="ผู้เข้าอบรมหลักสูตรการพัฒนาโมบายแอพ Flutter ระดับองค์กร"
            review="ได้ซื้อคอร์สอบรมออนไลน์กับทาง babel coder รู้สึกว่าอาจารย์สอนสนุก จึงได้ซื้อคอร์สออนไลน์มา 2-3 คอร์ส รวมทั้งยังแนะนำให้ทั้งฝ่ายซื้อคอร์สเรียนแบบ in house ซึ่งทุกคนพอใจในการสอนเป็นอย่างมาก และอาจารย์ยังให้คำแนะนำในเรื่องอื่นๆ กรณีติดปัญหาไม่รู้จะถามใคร ใครที่อยากเรียนเขียนโปรแกรมทุกระดับ แนะนำเลยครับ ไม่ผิดหวัง"
          ></CustomerReview>
          <CustomerReview
            avatar={teerapat}
            name="ธีรภัทร เชาว์เพ็ชร"
            attend="ทีมผู้เข้าร่วมการให้คำปรึกษาการพัฒนาเว็บด้วย React กับตลาดหลักทรัพย์แห่งประเทศไทย"
            review="เป็นคอร์สที่เรียนแล้วรู้สึกว่าคุ้มเกินราคาไปมากๆ นอกจากจะได้พื้นฐานในหัวข้อที่เรียนแล้ว ยังได้รู้ลึกรู้ละเอียดเกี่ยวกับการนำไปประยุกต์ใช้จริงในเคสต่างๆ รวมถึง Best Practice ที่ควรทำและระวังในการเขียนโค้ดอีกด้วย ทุกข้อสงสัยที่ถามกับอาจารย์ มักจะได้คำตอบที่ละเอียดมาก รวมถึงมีตัวอย่างมาให้พร้อมอีกด้วย ใครกำลังหาที่เรียนดีๆ ตั้งแต่พื้นฐานไปถึงขั้นสูง ผมขอแนะนำที่ BabelCoder เลยครับ ไม่ผิดหวังแน่นอน"
          ></CustomerReview>
          <CustomerReview
            avatar={chaiwat}
            name="ชัยวัฒน์ วัฒยากร"
            attend="ผู้เรียนออนไลน์คอร์สการพัฒนาเว็บสมัยใหม่ด้วย React"
            review="course จากทาง babelcoder อธิบายได้ละเอียด มีการพูดถึงที่มาที่ไป ให้เข้าใจเนื้อแท้ของสิ่งที่เรียนอยู่จริงๆ เรียนแล้วสามารถปูพื้นฐานเพื่อนำไปต่อยอดศึกษาในสิ่งที่ยากขึ้นได้ง่าย"
          ></CustomerReview>
          <CustomerReview
            avatar={nantawat}
            name="นันทวัฒน์ สาระแสน"
            attend="ผู้เรียนออนไลน์คอร์ส Automate Testing ด้วย Cypress"
            review="ผมรู้สึกว่าทางเพจแนะนำดีมากๆ เป็นมิตรมากๆครับ ให้การช่วยเหลือที่ดี อันไหนที่สามารถอธิบายได้ทางเพจก็จะอธิบายให้เราฟัง ซึ่งมันเป็นผลดีต่อผู้เข้าเรียนคอร์สอย่างมาก"
          ></CustomerReview>
          <CustomerReview
            avatar={thanapong}
            name="Thanapong Somjai"
            attend="ผู้เรียนออนไลน์คอร์สการพัฒนา RESTful API ด้วยภาษา Go"
            review="เนื้อหาคอร์สเรียนกระชับ เรียบเรียงดีมาก นำไปใช้งานได้จริงทั้งคอร์สเรียนออนไลน์และออฟไลน์ และยังคอยตอบคำถาม ให้คำปรึกษาและคำแนะนำที่มีประโยชน์มาก ๆ อยู่เสมอครับ"
          ></CustomerReview>
          <CustomerReview
            avatar={sudjai}
            name="Sudjai Jannual"
            attend="ผู้เข้าอบรมหลักสูตร Fullstack TypeScript ด้วย Nx, React, Express.js"
            review="จากที่ได้เรียนกับอาจารย์ผู้สอน พบว่าอาจารย์มีความรู้ความสามารถ และสามารถนำเอาประสบการณ์มาถ่ายทอดในการเรียนการสอนได้ดีครับ เนื้อหาที่อัดแน่น มีคุณภาพ รู้สึกดีใจครับ ที่ได้เรียนกับอาจารย์ท่านนี้ คุ้มกับเงินที่จ่ายครับ แต่ด้วยเวลาและตารางสอนที่ทางทีมขอมา มีจำกัด อาจต้องทบทวนย้อนหลังอีกทีนึง ขอบคุณครับ"
          ></CustomerReview>
          {/* <CustomerReview
            avatar={watchara}
            name="Watchara Santawee"
            attend="ผู้เรียนคอร์สอบรมการพัฒนาเว็บและ API บน Next.js ด้วย TypeScript, React, Next.js, Zustand และ tRPC"
            review="คิดว่าคอร์สนี้เป็นคอร์สที่ดีมากคอร์สนึงเลยสอนแบบทุกอย่างที่เป็น best practice ทั้งการจัดการ folder and file structure และการใช้พวก utitlity ต่างๆดีมากเลยครับ การ maintain code ที่อาจารย์สอนก็รู้สึกว่าง่ายมากครับ ที่ชอบมากๆคือการสอนการจัดการที่แนะนำว่าถ้าไปทำกับทีมควรจัดการแบบนี้มากกว่า แบบนี้ดีกว่า แนะนำแบบดีมากครับ รวมๆแล้วผมชอบมากครับได้ improvement skills จาก คอร์สนี้เยอะมากครับ 🌟"
          ></CustomerReview> */}
        </Reviews>
      </Container>
    </Wrapper>
  );
};

export default CustomerReviews;
